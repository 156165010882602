//数字转中文
export const toChinesNum = (num) => {
  const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'] // changeNum[0] = "零"
  const unit = ['', '十', '百', '千', '万']
  num = parseInt(num)
  const getWan = (temp) => {
    const strArr = temp.toString().split('').reverse()
    let newNum = ''
    for (var i = 0; i < strArr.length; i++) {
      newNum = (i == 0 && strArr[i] == 0 ? '' : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? '' : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum
    }
    return newNum
  }
  const overWan = Math.floor(num / 10000)
  let noWan = num % 10000
  if (noWan.toString().length < 4) noWan = '0' + noWan
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}

//base64转文件
export const base64toFile = (dataurl, filename = 'file')=>{
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  let file = new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  })
  return file
}
//文件转base64
export const filetoBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

//url转base64
export const getImageBase64Data = (imgSrc)=>{
  function getBase64(img) {
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    let dataURL = canvas.toDataURL('image/jpeg');
    return dataURL;
  }
  let baseUrl = new Promise(function (resolve, reject) {
    let image = new Image();
    image.crossOrigin = '*'; // 图片处理跨域
    if (imgSrc.indexOf('data:image/jpeg;base64') > -1) {
      image.src = imgSrc + '?v=' + Math.random(); // 处理图片从缓存获取
    } else {
      image.src = imgSrc;
    }
    image.onload = function () {
      let imageBase64Data = getBase64(image);
      resolve(imageBase64Data);
    }
  });
  return baseUrl;
}
//获取范围随机数，包括lower和upper
export const random = (lower, upper)=>{
	return Math.floor(Math.random() * (upper - lower + 1)) + lower;
}
//获取url参数
export const getUrlParams = (key="lalala")=>{
  var url = window.location.href;//获取当前url
  var dz_url = url.split('#')[0];//获取#/之前的字符串
  var cs = dz_url.split('?')[1];//获取?之后的参数字符串
  let params = {}
  if(cs){
    var cs_arr = cs.split('&');
    for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
      params[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
    }
  }
  return params[key] || params
}
//获取当前环境
export const browserInfo = ()=>{
  const UA = navigator.userAgent
  return {
    isAndroid: Boolean(UA.match(/android/ig)), // 是否是Android浏览器   
    isIos: Boolean(UA.match(/iphone|ipod|iOS/ig)), // 是否是苹果浏览器
    isIpad: Boolean(UA.match(/ipad/ig)), // 是否是 IPad 浏览器
    isLinux: Boolean(UA.match(/Linux/ig)), // 是否是Linux平台浏览器
    isWeixin: Boolean(UA.match(/MicroMessenger/ig)), // 是否是微信平台浏览器
    isAli: Boolean(UA.match(/AlipayClient/ig)), // 是否是支付宝平台浏览器
    isPhone: Boolean(/(iPhone|iPad|iPod|iOS|Android)/i.test(UA)) // 是否是手机端
  }
}
/* 
*   功能:实现VBScript的DateAdd功能. 
*   参数:interval,字符串表达式，表示要添加的时间间隔. 
*   参数:number,数值表达式，表示要添加的时间间隔的个数. 
*   参数:date,时间对象. 
*   返回:新的时间对象. 
*   var   now   =   new   Date(); 
*   var   newDate   =   DateAdd( "d ",5,now); 
*---------------   DateAdd(interval,number,date)   ----------------- 
*/ 
export const DateAdd = (interval,number,date)=>{
  switch(interval){  
    case "y":{
      date.setFullYear(date.getFullYear()+number);  
      return date;  
      break;  
    }  
    case "q":{
      date.setMonth(date.getMonth()+number*3);  
      return date;  
      break;  
    }  
    case "m":{
      date.setMonth(date.getMonth()+number);  
      return date;  
      break;  
    }  
    case "w":{
      date.setDate(date.getDate()+number*7);  
      return date;  
      break;  
    }  
    case "d":{
      date.setDate(date.getDate()+number);  
      return date;  
      break;  
    }  
    case "h":{
      date.setHours(date.getHours()+number);  
      return date;  
      break;  
    }  
    case "m":{
      date.setMinutes(date.getMinutes()+number);  
      return date;  
      break;  
    }  
    case "s":{
      date.setSeconds(date.getSeconds()+number);  
      return date;  
      break;  
    }  
    default:{
      date.setDate(date.getDate()+number);  
      return date;  
      break;  
    }  
  }  
}
//标准时间转格式化时间，例如：Wed Nov 23 2022 10:37:43 GMT+0800 (中国标准时间)   转为   2022/11/23 10:37:43
export const formatTime = (date=new Date()) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  return `${[year, month, day].map(formatNumber).join('/')} ${[hour, minute, second].map(formatNumber).join(':')}`
}
//不足10补0，例如：9 转为 09
export const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : `0${n}`
}